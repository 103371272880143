










import { PostStore } from '@/stores/post-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'rct-post-comment': () => import('@/modules/recruitment/posts/components/rct-post-comment.vue'),
    'rct-post-interact': () => import('@/modules/recruitment/posts/components/rct-post-interact.vue'),
  },
})
export default class extends Vue {
  @Prop({ default: {} }) postStore!: PostStore
  @Prop({ default: false }) enableBlind!: boolean
  @Prop({}) focusedComment?: string
  @Ref('rct-post-comment') rctPostComment: any

  requestReply() {
    this.rctPostComment?.requestReply()
  }
}
